import React, {useState} from "react";
import {css, StyleSheet} from "aphrodite";
import {ImageWithFallback} from "./ImageWithFallback";

const styles = StyleSheet.create({
    searchResultContainer: {
        backgroundColor: 'rgba(255,255,255,.04)',
        border: '1px solid rgba(255,255,255,.05)',
        padding: '8px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '10px',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(255,255,255,.08)',
        },
    },
})

const SearchResult = ({coin, handleClick}) => {
    const [showRewardInfo, setShowRewardInfo] = useState(false)

    const {name, percent_change_5m, last_price_in_usd, symbol, value, fdv, image_uri, multiplier, verified} = coin

    const bonusOn = multiplier > 1

    return (
        <div onMouseDown={handleClick} className={css(styles.searchResultContainer)}>
            <div style={{display: 'flex', alignItems: 'center', width: '240px', gap: '10px', overflow: 'hidden', boxSizing: 'border-box'}}>
                <div>
                    <ImageWithFallback
                        imageUri={image_uri || `${window.location.origin}/missing.png`}
                        fallbackUri={`${window.location.origin}/missing.png`}
                        name={name}
                        size={30}
                    />
                </div>
                { verified &&
                    (<div>
                        <img height={20} width={20} src={`${window.location.origin}/verified.png`} />
                    </div>)
                }
                <div style={{color: 'white', fontWeight: '700', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '200px'}}>
                    {name}
                </div>
                <div style={{color: 'rgb(106, 106, 109)'}}>
                    {symbol}
                </div>
            </div>
            <div style={{color: 'white', fontSize: '14px', width: '70px', textAlign: 'right'}}>
                {'$' + Number(last_price_in_usd).toFixed(6)}
            </div>
            <div style={{color: 'white', fontSize: '14px', width: '70px', textAlign: 'right'}}>
                {fdv}
            </div>
            {(bonusOn || true) && (
                <div style={{display: 'flex', alignItems: 'center', width: '70px', justifyContent: 'flex-end'}}>
                    <div onMouseOver={() => setShowRewardInfo(true)} onMouseOut={() => setShowRewardInfo(false)} style={{display: 'flex', gap: '5px', alignItems: 'center', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.05)', border: '1px solid rgba(255,255,255,.05)', padding: '8px', borderRadius: '10px' }}>
                        <div style={{color: '#bedcef', fontSize: '14px', fontWeight: '900'}}>
                            {`${multiplier}x`}
                        </div>
                        <img src={`${window.location.origin}/flake.png`} height={16} />
                        { showRewardInfo &&
                            (<div style={{color: 'rgba(255, 255, 255, 0.7)', boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px', position: 'absolute', whiteSpace: 'pre', fontSize: '14px', top: '40px', zIndex: 10, left: '-245px', display: 'flex', gap: '5px', alignItems: 'center', backgroundColor: '#0f1018', border: '1px solid #35353f', padding: '12px',borderRadius: '10px', transition: '0.3s all'}}>
                                {`Receive ${multiplier} times`}
                                <img src={`${window.location.origin}/flake.png`} height={16} />
                                for trading via FlakeSwap
                            </div>)
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default SearchResult