import React, {useContext, useEffect, useState} from "react";
import {css, StyleSheet} from "aphrodite";
import {commafy, origin, formatToThreeSignificantDigits, formatToXSignificantDigits} from "./utils";
import AppContext from "./AppContext";
import tokensHeader from "./TokensHeader";
import {ImageWithFallback} from "./ImageWithFallback";

const styles = StyleSheet.create({
    holdingContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        padding: '15px',
        display: 'inline-flex',
        gap: '15px',
        alignItems: 'center',
        borderRadius: '10px',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
    },
})

const Holding = ({isMini, holding, handleClick}) => {
    const {name, amount, symbol, decimals, isSol, tokenAddress, image, image_uri} = holding
    const {trades} = useContext(AppContext)

    const finalImage = image || image_uri



    const num = Number(amount)/(10**(Number(decimals)))

    const amt = formatToXSignificantDigits(num, 3)


    const priceOfSol = Number(trades['So11111111111111111111111111111111111111112']?.[0]?.price_of_token_in_usd) || 0
    const priceOfTokenInSol = Number(trades[tokenAddress]?.[0]?.price_of_token_in_sol || 0)
    const value = priceOfTokenInSol * (priceOfSol) * num

    const {historical_prices} = holding

    const currentPrice = Number(priceOfSol * priceOfTokenInSol)

    let price_change_1_day = 0
    if (historical_prices) {
        const {price_1_day_ago} = historical_prices
        price_change_1_day = (((currentPrice / price_1_day_ago) * 100) - 100).toFixed(2)

        if (price_1_day_ago === 0) {
            price_change_1_day = 0
        }
    }

    let adjustedName = name
    if (name.length > 15) {
        adjustedName = name.substr(0, 12) + '...'
    }

    if (value < 1 && tokenAddress !== 'So11111111111111111111111111111111111111112') {
        return null
    }

    return (
        <div onClick={() => handleClick({...holding, image: finalImage})} className={css(styles.holdingContainer)} style={{flexBasis: isMini ? '100%' : '49%'}}>
            <div>
                <ImageWithFallback
                    imageUri={finalImage || `${window.location.origin}/missing.png`}
                    fallbackUri={`${window.location.origin}/missing.png`}
                    name={name}
                    size={50}
                />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{color: 'white', fontWeight: '700'}}>
                    {adjustedName}
                </div>
                <div style={{color: '#666', fontSize: '13px'}}>
                    {`${amt} ${symbol}`}
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', justifySelf: 'flex-end', flexGrow: 1, textAlign: 'right'}}>
                <div style={{color: 'white', fontWeight: '700'}}>
                    ${formatToThreeSignificantDigits(value)}
                </div>
                <div style={{color: price_change_1_day >= 0 ? '#4fff69' : '#ff3800', fontSize: '12px'}}>
                    {`${price_change_1_day >= 0 ? '+' : ''}${price_change_1_day}%`}
                </div>
            </div>
        </div>
    )
}

export default Holding