import {
    makeApiRequest,
    generateSymbol,
    parseFullSymbol,
} from './helpers.js';
import {
    subscribeOnStream,
    unsubscribeFromStream,
} from './streaming.js';
import {origin} from "./utils";
import {symbol} from "prompts/lib/util/style";

const lastBarsCache = new Map();

// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1', '5', '15', '60'],


    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [{
        value: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
        name: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
        desc: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
    },{
        value: 'SWEEPPcXTbTmSpbXHGg4g8tvofqCukefut6s5hHUnba',
        name: 'SWEEPPcXTbTmSpbXHGg4g8tvofqCukefut6s5hHUnba',
        desc: 'SWEEPPcXTbTmSpbXHGg4g8tvofqCukefut6s5hHUnba',
    },{
        value: 'So11111111111111111111111111111111111111112',
        name: 'So11111111111111111111111111111111111111112',
        desc: 'So11111111111111111111111111111111111111112',
    },
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [{
        name: 'crypto',
        value: 'crypto',
    },
    ],
};

const fetchBars = async (token_address, from, to) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/bars?token_address=${token_address}&interval=${60}&from_ts=${from}&to_ts=${to}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const barCache = {}

const splitSymbol = (fullSymbol) => {
    const parts = fullSymbol.split(':');
    if (parts.length !== 2) {
        return null;  // Ensuring that the format is as expected
    }
    const [exchange, pair] = parts;
    const currencies = pair.split('/');
    if (currencies.length !== 2) {
        return null;  // Ensuring that the format is as expected
    }
    const [fromSymbol, toSymbol] = currencies;

    return {
        exchange: exchange,
        fromSymbol: fromSymbol,
        toSymbol: toSymbol,
    };
}

export default {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    // searchSymbols: async (
    //     userInput,
    //     exchange,
    //     symbolType,
    //     onResultReadyCallback,
    // ) => {
    //     console.log('[searchSymbols]: Method call');
    //     const symbols = await getAllSymbols();
    //     const newSymbols = symbols.filter(symbol => {
    //         const isExchangeValid = exchange === '' || symbol.exchange === exchange;
    //         const isFullSymbolContainsInput = symbol.full_name
    //             .toLowerCase()
    //             .indexOf(userInput.toLowerCase()) !== -1;
    //         return isExchangeValid && isFullSymbolContainsInput;
    //     });
    //     onResultReadyCallback(newSymbols);
    // },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        // console.log('[resolveSymbol]: Method call', symbolName);
        // const symbols = await getAllSymbols();
        // console.log("allSymbol", symbols)
        // const symbolItem = symbols.find(({
        //                                      full_name,
        //                                  }) => full_name === symbolName);
        // if (!symbolItem) {
        //     console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
        //     onResolveErrorCallback('cannot resolve symbol');
        //     return;
        // }

        // console.log('[ree]', symbolName)

        // const symbolInfo = generateSymbol(exchange.value, leftPairPart, rightPairPart);
        // return {
        //     symbol: symbol.short,
        //     full_name: symbol.full,
        //     description: symbol.short,
        //     exchange: exchange.value,
        //     type: 'crypto',
        // }

        const {exchange, fromSymbol, toSymbol} = splitSymbol(symbolName)

        // Symbol information object
        const symbolInfo = {
            ticker: symbolName,
            name: `${fromSymbol}/${toSymbol}`,
            description: `${fromSymbol}/${toSymbol}`,
            type: 'crypto',
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: exchange,
            minmov: 1,
            pricescale: 100000000,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 4,
            data_status: 'streaming',
        };

        console.log('[resolveSymbol]: Symbol resolved', symbolName);
        onSymbolResolvedCallback(symbolInfo);
    },

    // getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    //     if (barCache.bars) {
    //         onHistoryCallback(barCache.bars, {
    //             noData: false,
    //         })
    //         return
    //     }
    //     const barsResponse = await fetchBars("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm")
    //
    //     const bars = barsResponse.bars.map((bar, i) => {
    //         return {
    //             time: 1687651200000 + (100000000*(60 + i)),
    //             low: bar.low,
    //             high: bar.high,
    //             open: bar.open,
    //             close: bar.close,
    //             isBarClosed: true,
    //             isLastBar: false,
    //         }
    //     })
    //
    //     bars[bars.length - 1].isBarClosed = false
    //     bars[bars.length - 1].isLastBar = true
    //
    //     console.log("bars", bars)
    //
    //     barCache.bars = bars
    //
    //     onHistoryCallback(bars, {
    //         noData: false,
    //     })
    // },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
        console.log('[parsed]', symbolInfo)
        const urlParameters = {
            e: parsedSymbol.exchange,
            fsym: parsedSymbol.fromSymbol,
            tsym: parsedSymbol.toSymbol,
            toTs: to,
            limit: 2000,
        };
        // const query = Object.keys(urlParameters)
        //     .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
        //     .join('&');
        try {
            // const data = await makeApiRequest(`data/histoday?${query}`);

            const token_address = symbolInfo.exchange

            const barsResponse = await fetchBars(token_address, from, to)

            console.log("barsResponse", barsResponse)
            console.log("from", from)
            console.log("to", to)
            if (barsResponse.length === 0) {
                // "noData" should be set if there is no data in the requested period
                onHistoryCallback([], {
                    noData: true,
                });
                return;
            }
            // let bars = [];
            // data.Data.forEach(bar => {
            //     if (bar.time >= from && bar.time < to) {
            //         bars = [...bars, {
            //             time: bar.time * 1000,
            //             low: 2.65, //bar.low,
            //             high: 2.65, // bar.high,
            //             open: 2.65, // bar.open,
            //             close: 2.65, // bar.close,
            //         }];
            //     }
            // });

            const bars = barsResponse.bars.map((bar, i) => {
                console.log("[test]", bar.timestamp, from, to)
                if (bar.timestamp >= from && bar.timestamp < to) {
                    return {
                        time: bar.timestamp * 1000,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }
                }
            }).filter(bar => bar.open !== 0)

            // const bars = data.Data.map((bar, i) => {
            //     if (bar.time >= from && bar.time < to) {
            //         return {
            //             // time: bar.time * 1000,
            //             time: Math.floor(from + ((to - from) * (i / bars.length))) * 1000,
            //             low: bar.low,
            //             high: bar.high,
            //             open: bar.open,
            //             close: bar.close,
            //         }
            //     }
            //     return null
            // }).filter(b => b !== null)

            // if (bars.length > 0) {
            //     bars[0].time = from
            // }

            console.log('[getBars]: Response', bars);

            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.full_name, {
                    ...bars[bars.length - 1],
                });
            }
            console.log(`[getBars]: returned ${bars.length} bar(s)`);

            console.log("bars", bars)
            onHistoryCallback(bars, {
                noData: false,
            });
        } catch (error) {
            console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },

    getBars2: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
        const urlParameters = {
            e: parsedSymbol.exchange,
            fsym: parsedSymbol.fromSymbol,
            tsym: parsedSymbol.toSymbol,
            toTs: to,
            limit: 2000,
        };
        const query = Object.keys(urlParameters)
            .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
            .join('&');
        try {
            const data = await makeApiRequest(`data/histoday?${query}`);
            if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
                // "noData" should be set if there is no data in the requested period
                onHistoryCallback([], {
                    noData: true,
                });
                return;
            }
            let bars = [];
            data.Data.forEach(bar => {
                if (bar.time >= from && bar.time < to) {
                    bars = [...bars, {
                        time: bar.time * 1000,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }];
                }
            });
            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.full_name, {
                    ...bars[bars.length - 1],
                });
            }
            console.log(`[getBars]: returned ${bars.length} bar(s)`);

            console.log("bars", bars)
            onHistoryCallback(bars, {
                noData: false,
            });
        } catch (error) {
            console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
    ) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.full_name),
            lastBarsCache
        );
    },

    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        unsubscribeFromStream(subscriberUID);
    },
};