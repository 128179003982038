import Tokens from "./Tokens";
import oldStyles from "./oldStyles";
import TradingViewWidget from "./TradingViewWidget";
import Trades from "./Trades";
import Swapper from "./Swapper";
import Holdings from "./Holdings";
import React, {useContext, useEffect, useState} from "react";
import {ImageWithFallback} from "./ImageWithFallback.js"
import AppContext from "./AppContext";

const coinsInit = [
    {
        uri: 'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Fsolana-labs%2Ftoken-list%40main%2Fassets%2Fmainnet%2FSo11111111111111111111111111111111111111112%2Flogo.png&fit=cover&width=256&height=256',
        name: 'Solana',
        amount: 0,
        symbol: 'SOL',
        token_address: 'So11111111111111111111111111111111111111112',
        tokenAddress: 'So11111111111111111111111111111111111111112',
        change: '0%',
        decimals: 9,
        isSol: true
    },
    {
        uri: 'https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f7261772e67697468756275736572636f6e74656e742e636f6d2f736f6c616e612d6c6162732f746f6b656e2d6c6973742f6d61696e2f6173736574732f6d61696e6e65742f45506a465764643541756671535371654d32714e31787a7962617043384734774547476b5a777954447431762f6c6f676f2e706e67',
        name: 'USDC',
        amount: 0,
        symbol: 'USDC',
        token_address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        tokenAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        change: '0%',
        decimals: 6,
        isSol: false
    },
]

const Dashboard = ({setCoinSelected}) => {
    const {isMobile, coinSelected} = useContext(AppContext)

    let fromToken = coinsInit[0]

    if (coinSelected.token_address === 'So11111111111111111111111111111111111111112') {
        fromToken = coinsInit[1]
    }

    return (
        <div style={{display: 'flex', gap: '20px', flexGrow: 1, flexDirection: isMobile ? "column": "row"}}>
            <div style={{gap: '20px', display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '800px'}}>
                <Holdings setCoinSelected={setCoinSelected} />
                <Tokens setCoinSelected={setCoinSelected} />
            </div>
            <div style={{width: '100%', height: '100%', minHeight: isMobile ? '1010px' : undefined, display: 'flex', flexDirection: 'column', gap: '20px', flexGrow: 1}}>
                <div style={{...oldStyles.box, flex: 1, height: '100px'}}>
                    <div style={{fontWeight: '700', fontSize: '18px', display: 'flex', gap: '20px', alignItems: 'center'}}>
                        {/*<img style={{borderRadius: '50%'}} height={50} width={50} src={coinSelected.icon ?? coinSelected.image ?? coinSelected.uri ?? coinSelected.image_uri} alt={`${coinSelected.name} icon`} />*/}
                        <ImageWithFallback
                            imageUri={coinSelected.icon ?? coinSelected.image ?? coinSelected.uri ?? coinSelected.image_uri}
                            fallbackUri={`${window.location.origin}/missing.png`}
                            name={coinSelected.name}
                            size={50}
                        />
                        <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                            {`${coinSelected.name}`}
                            <div style={{fontWeight: '400', color: 'rgb(106, 106, 109)', fontSize: '14px'}}>
                                {`${coinSelected.ticker ?? coinSelected.symbol}`}
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '500px'}}>
                        <TradingViewWidget key={coinSelected.token_address} coinSelected={coinSelected} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', height: '400px', gap: '20px', boxSizing: 'border-box'}}>
                    <Trades />
                    <Swapper key={coinSelected.token_address} fromToken={fromToken} toToken={coinSelected} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard