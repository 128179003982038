import React, {useEffect, useRef, useState, useMemo} from 'react'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react'
import { PhantomWalletAdapter, PhantomWalletName } from '@solana/wallet-adapter-phantom'
import { StyleSheet, css } from 'aphrodite'
import {Helmet} from "react-helmet";
import LaunchToken from "./LaunchToken"
import Airdrop from "./Airdrop";
import Header from "./Header";
import Dashboard from "./Dashboard";
import ClaimModal from "./ClaimModal";
import websocketService from "./Websocket.js"
import {apiKey} from "./helpers";
import AppContext from "./AppContext";

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Lato',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {
    height: '80px',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
})

function getLibrary(provider, connector) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const pageMap = {
  'dashboard': Dashboard,
  'airdrop': Airdrop,
  'launch': LaunchToken
}
let count = 0

const App = () => {
  const adapter = new PhantomWalletAdapter();
  const [page, setPage] = useState('dashboard')
  const [width, setWidth] = useState(window.innerWidth); // check width size of the window
  const [isMobile, setIsMobile] = useState(false)
  const [coinSelected, setCoinSelected] = useState(coinsInit[0])
  const [tokens, setTokens] = useState({})
  const [socketOpen, setSocketOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [trades, setTrades] = useState({})
  const [account, setAccount] = useState({})
  const [socket, setSocket] = useState(null)
  const [holdings, setHoldings] = useState([])
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setIsMobile(window.innerWidth < 1400)
  };

  const openFn = (client) => {
    const tokensToAdd = {
      add_symbols: ['So11111111111111111111111111111111111111112', 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v']
    };
    setSocketOpen(true)
    client.send(JSON.stringify(tokensToAdd))

    setSocket(client)
  }

  const messageFn = (event) => {
    const receivedMessage = event.data

    console.log("[Received Trade]", receivedMessage)

    try {
      const messageObject = JSON.parse(receivedMessage)

      setTrades(existingTrades => {
        const tokenAddressTrades = existingTrades[messageObject.token_address] || [];
        const isMessageObjectPresent = tokenAddressTrades.some(trade => trade.signature === messageObject.signature);

        if (isMessageObjectPresent) {
          return existingTrades;
        }

        return {
          ...existingTrades,
          [messageObject.token_address]: [messageObject, ...tokenAddressTrades]
        };
      });

      // setTrades(existingTrades => {
      //   return {...existingTrades, [messageObject.token_address]: [messageObject, ...(existingTrades[messageObject.token_address] || [])]}
      // })
    } catch (error) {
      console.log(error)
    }
  }

  const closeFn = () => {
    setSocket(null)
    setSocketOpen(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange()

    websocketService.addHandlers(openFn, messageFn, closeFn)
    websocketService.connect()


    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [])


  const Page = pageMap[page]

  return (
      <AppContext.Provider value={{tokens, setTokens, socket, isMobile, socketOpen, trades, account, setAccount, setShowModal, coinSelected, setTrades, setHoldings, holdings}}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ConnectionProvider
              endpoint={"https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/"}>
            <WalletProvider wallets={[adapter]} autoConnect>
              <Helmet>
                <script
                    type="text/javascript"
                    src="/charting_library/charting_library.standalone.js"
                ></script>
                <script
                    type="text/javascript"
                    src="/datafeeds/udf/dist/bundle.js"
                ></script>
              </Helmet>
              <div className={css(styles.container)}>
                <div className={css(styles.header)}>
                  <Header page={page} setPage={setPage} setCoinSelected={setCoinSelected}/>
                </div>
                <div style={{height: isMobile ? "100%" : 'calc(100svh - 80px)', display: 'flex', overflow: isMobile ? 'scroll' : 'hidden', width: '100%', padding: '0 20px 20px 20px',}}>
                  <Page coinSelected={coinSelected} setCoinSelected={setCoinSelected}/>
                  {showModal && <ClaimModal closeModal={() => setShowModal(false)} />}
                </div>
              </div>
            </WalletProvider>
          </ConnectionProvider>
        </Web3ReactProvider>
      </AppContext.Provider>
  )
}

const coinsInit = [
  {
    uri: 'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Fsolana-labs%2Ftoken-list%40main%2Fassets%2Fmainnet%2FSo11111111111111111111111111111111111111112%2Flogo.png&fit=cover&width=256&height=256',
    name: 'Solana',
    amount: 0,
    symbol: 'SOL',
    token_address: 'So11111111111111111111111111111111111111112',
    tokenAddress: 'So11111111111111111111111111111111111111112',
    change: '0%',
    decimals: 9,
    isSol: true
  }
]
export default App;
