import React from "react";

const tradeGridTemplateColumns = '100px 70px minmax(85px, 1fr) minmax(105px, 1fr) minmax(80px, 1fr) 50px'

const TradeHeader = () => {
    return (
        <div style={{display: 'grid',
            gridTemplateColumns: tradeGridTemplateColumns,
            fontSize: '16px', position: 'relative', boxSizing: 'border-box', padding: '5px 15px', overflow: 'scroll', scrollbarWidth: 'none', color: 'rgba(255, 255, 255, 0.8)'}}>
            <div>
                Time
            </div>
            <div>
                Type
            </div>
            <div>
                Volume
            </div>
            <div>
                Price
            </div>
            <div>
                Quantity
            </div>
            <div style={{textAlign: 'right'}}>
                Link
            </div>
        </div>
    )
}

export default TradeHeader