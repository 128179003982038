import React, {useEffect, useRef, useState} from "react"
import {css, StyleSheet} from "aphrodite";

const styles = StyleSheet.create({
    title: {
        fontWeight: '900',
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        gap: '10px',
    },
    divider: {
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        width: '100%',
    },
    information: {
    },
    link: {
        fontSize: '12px',
        textDecoration: 'underline',
        color: 'rgb(106, 106, 109)',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            color: 'rgb(255, 255, 255)',
        },
    },
    linkInner: {
        fontSize: '12px',
        textDecoration: 'underline',
        color: 'rgb(106, 106, 109)',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            color: 'rgb(255, 255, 255)',
        },
    }
})

const oldStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        position: 'fixed',
        bottom: '10px',
        maxWidth: '500px',
        color: 'rgba(255, 255, 255, 0.7)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px',
        whiteSpace: 'pre',
        fontSize: '14px',
        zIndex: 10,
        left: '-275px',
        backgroundColor: '#0f1018',
        border: '1px solid #35353f',
        padding: '20px',
        borderRadius: '10px',
        transition: '0.3s all'
    },
}

const targetLeft = 10

const Toast = ({title, information, icon, link}) => {
    const [left, setLeft] = useState(-310)
    const [middleDuration, setMiddleDuration] = useState(300)
    const [hitCenter, setHitCenter] = useState(false)

    const leftRef = useRef(left);
    leftRef.current = left;

    const middleDurationRef = useRef(middleDuration);
    middleDurationRef.current = middleDuration;

    const hitCenterRef = useRef(hitCenter);
    hitCenterRef.current = hitCenter;

    const frameId = useRef();

    const animate = () => {
        const currentLeft = leftRef.current;
        const currentMiddleDuration = middleDurationRef.current;
        const currentHitCenter = hitCenterRef.current;

        if (!currentHitCenter && targetLeft - currentLeft < 0.3) {
            setLeft(targetLeft);
            setHitCenter(true);
        } else if (!currentHitCenter) {
            setLeft(l => l + ((targetLeft - l) / 5));
        } else if (currentMiddleDuration > 0) {
            setMiddleDuration(m => m - 1);
        } else {
            setLeft(l => l + ((-310 - l) / 10));
        }

        frameId.current = window.requestAnimationFrame(animate);
    };

    useEffect(() => {
        frameId.current = window.requestAnimationFrame(animate);
        return () => cancelAnimationFrame(frameId.current);
    }, []);

    return (
        <div style={{...oldStyles.container, left: `${left}px`}}>
            <div className={css(styles.title)}>
                { icon === "check" &&
                    (<div>
                        <img height={40} width={40} src={`${window.location.origin}/verified.png`} />
                    </div>)
                }
                {title}
            </div>
            <div className={css(styles.divider)} />
            <div className={css(styles.information)}>
                {information}
            </div>
            { link && (
                <div className={css(styles.link)}>
                    <a target="_blank" href={link.uri}>
                        <div className={css(styles.linkInner)}>
                            {link.text}
                        </div>
                    </a>
                </div>
                )
            }
        </div>
    )
}

export default Toast