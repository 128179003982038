import React, {useEffect, useState} from "react"

export const ImageWithFallback = ({ imageUri, fallbackUri, name, size }) => {
    const [src, setSrc] = useState(imageUri);

    useEffect(() => {
        setSrc(imageUri)
    }, [imageUri])
    const handleError = () => {
        setSrc(fallbackUri);
    }

    return (
        <img
            style={{ borderRadius: '50%' }}
            height={size}
            width={size}
            src={src}
            alt={`${name} icon`}
            onError={handleError}
        />
    );
}