import AirdropWidget from "./AirdropWidget";
import ReferralWidget from "./ReferralWidget";
import React from "react";


const Airdrop = () => {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '50px'}}>
            <AirdropWidget />
            <ReferralWidget />
        </div>
    )
}

export default Airdrop