import { parseFullSymbol, apiKey } from './helpers.js';
import websocketService from "./Websocket.js"

const channelToSubscription = new Map();
// socket.addEventListener('message', (event) => {
//     const receivedMessage = event.data
//
//     try {
//         const messageObject = JSON.parse(receivedMessage)
//
//         const {trade_type, amm_address, token_address, created_at, price_of_token_in_usd} = messageObject
//
//         const tradePrice = parseFloat(price_of_token_in_usd)
//         const tradeTime = new Date(created_at).getTime()
//
//         let fromSymbol = token_address
//         let toSymbol = 'So11111111111111111111111111111111111111112'
//
//         if (trade_type === 'sell') {
//             fromSymbol = 'So11111111111111111111111111111111111111112'
//             toSymbol = token_address
//         }
//
//         const channelString = `0-${amm_address}~${fromSymbol}~${toSymbol}`
//
//         const oldBar = {
//             time: 123,
//             interval: 60,
//             high: 123,
//             low: 120,
//             close: 120
//         }
//         const nextBarTime = bar.time + bar.interval
//
//         const shouldCreateNewBar = tradeTime > nextBarTime // nextDailyBarTime
//
//         let bar
//         if (shouldCreateNewBar) {
//             bar = {
//                 time: nextBarTime,
//                 open: tradePrice,
//                 high: tradePrice,
//                 low: tradePrice,
//                 close: tradePrice,
//             };
//             console.log('[socket] Generate new bar', bar);
//         } else {
//             bar = {
//                 ...nextBarTime,
//                 high: Math.max(oldBar.high, tradePrice),
//                 low: Math.min(oldBar.low, tradePrice),
//                 close: tradePrice,
//             };
//             console.log('[socket] Update the latest bar by price', tradePrice);
//         }
//     } catch (error) {
//         console.log(error)
//     }
// })

// socket.addEventListener('message', (event) => {
//     const data = JSON.parse(event.data);
//     console.log('[socket] Message:', data);
//     const {
//         TYPE: eventTypeStr,
//         M: exchange,
//         FSYM: fromSymbol,
//         TSYM: toSymbol,
//         TS: tradeTimeStr,
//         P: tradePriceStr,
//     } = data;
//
//     if (parseInt(eventTypeStr) !== 0) {
//         // Skip all non-trading events
//         return;
//     }
//     const tradePrice = parseFloat(tradePriceStr);
//     const tradeTime = parseInt(tradeTimeStr);
//     const channelString = `0~${exchange}~${fromSymbol}~${toSymbol}`;
//     const subscriptionItem = channelToSubscription.get(channelString);
//     if (subscriptionItem === undefined) {
//         return;
//     }
//     const lastDailyBar = subscriptionItem.lastDailyBar;
//     const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
//
//     let bar;
//     if (tradeTime >= nextDailyBarTime) {
//         bar = {
//             time: nextDailyBarTime,
//             open: tradePrice,
//             high: tradePrice,
//             low: tradePrice,
//             close: tradePrice,
//         };
//         console.log('[socket] Generate new bar', bar);
//     } else {
//         bar = {
//             ...lastDailyBar,
//             high: Math.max(lastDailyBar.high, tradePrice),
//             low: Math.min(lastDailyBar.low, tradePrice),
//             close: tradePrice,
//         };
//         console.log('[socket] Update the latest bar by price', tradePrice);
//     }
//     subscriptionItem.lastDailyBar = bar;
//
//     // Send data to every subscriber of that symbol
//     // subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
// });

const handleNewTrade = (trade, callback, interval, lastBarsCache, full_name) => {
    const lastBar = lastBarsCache.get(full_name)
    const nextBarTime = lastBar.time + (interval * 1000)
    let bar
    const { price_of_token_in_usd } = trade
    console.log("[bar bar]", trade.time * 1000, nextBarTime, lastBar.time)
    if (trade.time * 1000 >= nextBarTime) {
        bar = {
            time: nextBarTime,
            low: price_of_token_in_usd,
            high: price_of_token_in_usd,
            open: lastBar.close,
            close: price_of_token_in_usd,
        }
    } else {
        bar = {
            ...lastBar,
            high: Math.max(lastBar.high, price_of_token_in_usd),
            low: Math.min(lastBar.low, price_of_token_in_usd),
            close: price_of_token_in_usd,
        }
    }
    lastBarsCache.set(full_name, bar)
    callback(bar)
}


export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastBar,
    lastBarsCache
) {

    console.log('[abc]', lastBar, resolution, symbolInfo)

    // resolution is resolution in minutes, I need it in seconds

    const myFn = (event) => {
        const trade = JSON.parse(event.data)
        const exchange = symbolInfo.exchange
        if (trade.token_address === exchange) {
            handleNewTrade(trade, onRealtimeCallback, resolution * 60, lastBarsCache, symbolInfo.full_name)
        }
    }
    websocketService.addListener(myFn)





    // const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    // const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
    // const handler = {
    //     id: subscriberUID,
    //     callback: onRealtimeCallback,
    // };
    // let subscriptionItem = channelToSubscription.get(channelString);
    // if (subscriptionItem) {
    //     // Already subscribed to the channel, use the existing subscription
    //     subscriptionItem.handlers.push(handler);
    //     return;
    // }
    // subscriptionItem = {
    //     subscriberUID,
    //     resolution,
    //     lastDailyBar,
    //     handlers: [handler],
    // };
    // channelToSubscription.set(channelString, subscriptionItem);
    // console.log(
    //     '[subscribeBars]: Subscribe to streaming. Channel:',
    //     channelString
    // );
    // const subRequest = {
    //     action: 'SubAdd',
    //     subs: [channelString],
    // };
    // socket.send(JSON.stringify(subRequest));
}

export function unsubscribeFromStream(subscriberUID) {
    // Find a subscription with id === subscriberUID
    for (const channelString of channelToSubscription.keys()) {
        const subscriptionItem = channelToSubscription.get(channelString);
        const handlerIndex = subscriptionItem.handlers.findIndex(
            (handler) => handler.id === subscriberUID
        );

        if (handlerIndex !== -1) {
            // Remove from handlers
            subscriptionItem.handlers.splice(handlerIndex, 1);

            if (subscriptionItem.handlers.length === 0) {
                // Unsubscribe from the channel if it was the last handler
                console.log(
                    '[unsubscribeBars]: Unsubscribe from streaming. Channel:',
                    channelString
                );
                const subRequest = {
                    action: 'SubRemove',
                    subs: [channelString],
                };
                // socket.send(JSON.stringify(subRequest));
                channelToSubscription.delete(channelString);
                break;
            }
        }
    }
}