import React, {useContext, useEffect, useState} from "react";
import oldStyles from "./oldStyles";
import TradeHeader from "./TradeHeader";
import Trade from "./Trade";
import {origin} from "./utils"
import AppContext from "./AppContext";
import TokensHeader from "./TokensHeader";
import Token from "./Token";

const getTrades = async (tokenAddress) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/trades/${tokenAddress}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const Trades = () => {
    const {isMobile, coinSelected, trades, setTrades} = useContext(AppContext)

    useEffect(() => {
        if (coinSelected && coinSelected.token_address) {
            findTrades(coinSelected.token_address)
        }
    }, [coinSelected])
    const findTrades = async(tokenAddress) => {
        const res = await getTrades(tokenAddress)
        console.log("trades", res.trades)

        setTrades(existingTrades => {
            return {...existingTrades, [tokenAddress]: res.trades}
        })
    }

    let topVolume = 0

    const activeTokenTrades = trades[coinSelected.token_address] || []
    console.log("[wtf]", activeTokenTrades)

    activeTokenTrades.forEach(trade => {
        if (Number(trade.volume_in_usd) > topVolume) {
            topVolume = Number(trade.volume_in_usd)
        }
    })

    return (
        <div style={{...oldStyles.box, flexGrow: 1, maxHeight: '400px', width: isMobile ? 'calc(100svw - 40px)' : '0', scrollbarWidth: 'none', overflow: isMobile ? 'inherit' : 'scroll'}}>
            <div style={{fontWeight: '700', fontSize: '18px', marginBottom: '-10px'}}>
                <TradeHeader />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '25px', width: '100%', overflowY: 'auto', scrollbarWidth: 'none'}}>
                {activeTokenTrades.map((trade, key) => <Trade trade={trade} topVolume={topVolume} />)}
            </div>
        </div>
    )
}

export default Trades