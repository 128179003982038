const oldStyles = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
        backgroundColor: 'rgba(255,255,255,.04)',
        border: '1px solid rgba(255,255,255,.05)',
        borderRadius: '10px'
    },
}

export default oldStyles