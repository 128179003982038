import React, {useContext, useEffect, useRef, useState} from "react";
import oldStyles from "./oldStyles";
import {css, StyleSheet} from "aphrodite";
import {formatToXSignificantDigits, origin} from "./utils";
import AppContext from "./AppContext";
import {useWallet} from "@solana/wallet-adapter-react";
import {useOnClickOutside} from "./useOnClickOutside";
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, PublicKey, Transaction, sendAndConfirmTransaction } from '@solana/web3.js'
import {
    Liquidity,
    LIQUIDITY_STATE_LAYOUT_V4,
    LiquidityPoolKeys,
    LiquidityStateV4,
    MARKET_STATE_LAYOUT_V3,
    MarketStateV3,
    Token,
    TokenAmount,
} from '@raydium-io/raydium-sdk'
import Swap, {getPoolKeys, convertKeys} from "./Swap";
import Toast from "./Toast";
import {ImageWithFallback} from "./ImageWithFallback";

const connection = new Connection("https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/", 'confirmed')

const styles = StyleSheet.create({
    miniButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: '10px 15px',
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.7)',
        alignItems: 'center',
        borderRadius: '10px',
        fontSize: '14px',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            color: 'rgba(255, 255, 255, 1)'
        },
    },
    exchangeButton: {
        border: '1px solid rgb(32 129 226)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '700',
        fontSize: '14px',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
    },
    exchangeOption: {
        backgroundColor: 'rgba(255,255,255,.04)',
        border: '1px solid rgba(255,255,255,.05)',
        padding: '8px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '10px',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(255,255,255,.08)',
        },
    },
    primaryButton: {
        background: 'rgb(32 129 226)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '900',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgb(69, 145, 222)',
        },
    },
    disabledButton: {
        background: 'rgb(59,59,59)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '900',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'disabled',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        position: 'fixed',
        bottom: '10px',
        color: 'rgba(255, 255, 255, 0.7)',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px',
        whiteSpace: 'pre',
        fontSize: '14px',
        zIndex: 10,
        left: '10px',
        backgroundColor: '#0f1018',
        border: '1px solid #35353f',
        padding: '20px',
        borderRadius: '10px',
        transition: '0.3s all'
    },
    divider: {
        height: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        width: '100%',
    },
})

const updateTrade = (signature) => {
    console.log("called")
    try {
        let body = {
            signature
        }
        const headers = {'Content-Type': 'application/json'}
        fetch(`${origin}/flakeswap_trades`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from trade', res)
                if (res.success) {

                } else {

                }
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    } catch(e) {
        return Promise.resolve({})
    }
}

// const handleSwap = async() => {
//     const { transaction, signers } = await Liquidity.makeSwapTransaction({
//         connection,
//         poolKeys: raySolPoolKey,
//         userKeys: {
//             tokenAccounts,
//             owner: publicKey,
//         },
//         amountIn,
//         amountOut: minAmountOut,
//         fixedSide: "in"
//     });
// }
//
// const SolanaSignMessage = () => {
//     const wallet = useWallet();
//
//     const signMessage = async (message) => {
//         if (!wallet.connected) {
//             console.log('Connect to a wallet first.');
//             return;
//         }
//         try {
//             const encodedMessage = new TextEncoder().encode(message);
//             const signature = await wallet.signMessage(encodedMessage, "utf8");
//             console.log('Signature:', signature);
//             return signature;
//         } catch (error) {
//             console.error('Error signing message:', error);
//         }
//     };
//
//     return (
//         <div>
//             <button onClick={() => signMessage("Hello from Solana!")}>Sign Message</button>
//         </div>
//     );
// }

const SwapButton = (sourceToken, targetToken, amount) =>{
    // const wallet = useWallet();

    // async function handleSwap() {
    //     if (!wallet.connected) {
    //         console.log('Please connect your wallet.');
    //         return;
    //     }
    //
    //     try {
    //         const sourcePublicKey = new PublicKey(sourceToken);
    //         const targetPublicKey = new PublicKey(targetToken);
    //         const sourceTokenAccount = await Token.getAssociatedTokenAddress(
    //             ASSOCIATED_TOKEN_PROGRAM_ID,
    //             TOKEN_PROGRAM_ID,
    //             sourcePublicKey,
    //             wallet.publicKey
    //         );
    //         const destinationTokenAccount = await Token.getAssociatedTokenAddress(
    //             ASSOCIATED_TOKEN_PROGRAM_ID,
    //             TOKEN_PROGRAM_ID,
    //             targetPublicKey,
    //             wallet.publicKey
    //         );
    //
    //         // Construct your swap transaction here
    //         // This is highly dependent on the Raydium API or SDK
    //         const transaction = new Transaction();
    //         // Add instructions to the transaction
    //         transaction.add(
    //             // Swap instruction goes here
    //         );
    //
    //         // Send transaction
    //         const signature = await wallet.sendTransaction(transaction, connection);
    //         await connection.confirmTransaction(signature);
    //         console.log('Swap completed with signature:', signature);
    //     } catch (error) {
    //         console.error('Swap failed:', error);
    //     }
    // }

    // const swapTokens = async (sourceMint, destinationMint, amountToSwap) => {
    //     if (!wallet.connected) {
    //         return;
    //     }
    //
    //     // Assume we have a function that prepares Raydium's specific swap instruction
    //     const swapInstruction = prepareRaydiumSwapInstruction(
    //         sourceMint,
    //         destinationMint,
    //         amountToSwap,
    //         wallet.publicKey,
    //         poolProgramId,
    //         poolAccount
    //     );
    //
    //     const transaction = new Transaction().add(swapInstruction);
    //
    //     // Send transaction
    //     try {
    //         let signature = await wallet.sendTransaction(transaction, connection);
    //         await connection.confirmTransaction(signature, 'confirmed');
    //         console.log('Transaction successful with signature:', signature);
    //     } catch (error) {
    //         console.error('Transaction failed:', error);
    //     }
    // }
    //
    // return (
    //     <button onClick={() => swapTokens(sourceToken, targetToken, 0.01)}>
    //         Swap Tokens
    //     </button>
    // );
}

/*

0. const SOL_USDC_POOL_ID = "58oQChx4yWmvKdwLLZzBi4ChoCc2fqCUWBkwMihLYQo2"
1. connection.getAccountInfo(new PublicKey(SOL_USDC_POOL_ID))
2. const decodedData = LIQUIDITY_STATE_LAYOUT_V4.decode(accountInfo.data)
3. buy(??, decodedData)

 */



const prepareRaydiumInstruction = async(accountId, accountData) => {
    // let tokenAccount = existingTokenAccounts.get(accountData.baseMint.toString());
    let tokenAccount = 'w9gCyaHir87PpBQUWQmeivg76fQGD3FPewXngfVJDfn'

    if (!tokenAccount) {
        // const market = await getMinimalMarketV3(solanaConnection, accountData.marketId, commitment);
        // tokenAccount = saveTokenAccount(accountData.baseMint, market);
    }

    // console.log("keep it going")
    // tokenAccount.poolKeys = createPoolKeys(accountId, accountData, tokenAccount.market!);
    // console.log("pool keys created")
    // const { innerTransaction, address } = Liquidity.makeSwapFixedInInstruction(
    //     {
    //         poolKeys: tokenAccount.poolKeys,
    //         userKeys: {
    //             tokenAccountIn: quoteTokenAssociatedAddress,
    //             tokenAccountOut: tokenAccount.address,
    //             owner: wallet.publicKey,
    //         },
    //         amountIn: quoteAmount.raw,
    //         minAmountOut: 0,
    //     },
    //     tokenAccount.poolKeys.version,
    // );
    //
    // const latestBlockhash = await solanaConnection.getLatestBlockhash({
    //     commitment: commitment,
    // });
    // const messageV0 = new TransactionMessage({
    //     payerKey: wallet.publicKey,
    //     recentBlockhash: latestBlockhash.blockhash,
    //     instructions: [
    //         ComputeBudgetProgram.setComputeUnitLimit({ units: 400000 }),
    //         ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 30000 }),
    //         createAssociatedTokenAccountIdempotentInstruction(
    //             wallet.publicKey,
    //             tokenAccount.address,
    //             wallet.publicKey,
    //             accountData.baseMint,
    //         ),
    //         ...innerTransaction.instructions,
    //     ],
    // }).compileToV0Message();
    // console.log("getting things ready")
    // const transaction = new VersionedTransaction(messageV0);
    // transaction.sign([wallet, ...innerTransaction.signers]);
    // console.log("signed")
    // const signature = await solanaConnection.sendRawTransaction(transaction.serialize(), {
    //     maxRetries: 20,
    //     preflightCommitment: commitment,
    // });
}

let toastId = 1

const Swapper = ({fromToken, toToken}) => {
    const [toasts, setToasts] = useState([])

    const [selectedLP, setSelectedLP] = useState(null)

    const [from, setFrom] = useState(fromToken)
    const [to, setTo] = useState(toToken)
    const [lpInfos, setLPInfos] = useState([])

    const [fromAmt, setFromAmt] = useState('')
    const [toAmt, setToAmt] = useState('')

    const [showExchangeDropdown, setShowExchangeDropdown] = useState(false)

    const {isMobile, trades, holdings} = useContext(AppContext)
    const wallet = useWallet()

    const priceOfSol = Number(trades['So11111111111111111111111111111111111111112']?.[0]?.price_of_token_in_usd) || 0

    const priceOfFromTokenInSol = Number(trades[from.token_address]?.[0]?.price_of_token_in_sol) || 0
    const currentFromPrice = Number(priceOfSol * priceOfFromTokenInSol)

    const priceOfToTokenInSol = Number(trades[to.token_address]?.[0]?.price_of_token_in_sol) || 0
    const currentToPrice = Number(priceOfSol * priceOfToTokenInSol)

    const [fullAmt, setFullAmt] = useState(0)
    const [awaitingSignature, setAwaitingSignature] = useState(false)
    const [awaitingConfirmation, setAwaitingConfirmation] = useState(false)

    const modalRef = useRef();
    useOnClickOutside(modalRef, () => setShowExchangeDropdown(false));

    const updateFromAmount = (e) => {
        const value = e.target.value
        if (/^-?\d*\.?\d*$/.test(value)) {
            setFromAmt(value)
            const usdFrom = (Number(from.last_price_in_usd) * Number(fromAmt))

            if (value.length > 0) {
                setToAmt(((usdFrom / Number(to.last_price_in_usd)) || 0).toFixed(1))
            } else {
                setToAmt('')
            }
        }
    }

    const updateToAmount = (value) => {
        setToAmt(value)
    }

    useEffect(() => {
        let matchedHolding = holdings.find(holding => holding.token_address === from.token_address)
        setFullAmt( (matchedHolding && matchedHolding.decimals) ? (Number(matchedHolding.amount) / (10**matchedHolding.decimals)) : 0)
    }, [from, holdings])

    useEffect(() => {
        setLPInfos([])
        setFrom(fromToken)
        setTo(toToken)
    }, [fromToken, toToken, setFrom, setTo])

    const help = async(t1, t2) => {
        const data = await getPoolKeys(t1, t2)

        const lp_infos = data.pool_keys.map((pk, index) => {
            const obj = {
                pool_key: pk,
                liquidity_pool: data.liquidity_pools[index]
            }
            return obj
        })
        if (lp_infos.length > 0) {
            setSelectedLP(lp_infos[0])
        } else {
            setSelectedLP(null)
        }
        setLPInfos(lp_infos)
    }

    useEffect(() => {
        help(from.token_address, to.token_address)
    }, [from, to])

    useEffect(() => {
        if (fromAmt.length > 0) {
            const exchangeRate =  priceOfFromTokenInSol / priceOfToTokenInSol
            const newToAmt = (exchangeRate * Number(fromAmt)) || 0
            setToAmt(`${newToAmt}`)
        }
    }, [fromToken, toToken, fromAmt])

    const switchToAndFrom = () => {
        let temp = from
        setFrom(to)
        setTo(temp)
        setFromAmt(toAmt)
    }

    const renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} icon={toast.icon} link={toast.link} />
        )
    }

    const renderToasts = () => {
        return (<div>{toasts.map(renderToast)}</div>)
    }

    const testSwap = async(wallet, from_token_address, to_token_address, amount, setToasts) => {
        const swapConfig = {
            executeSwap: false, // Send tx when true, simulate tx when false
            useVersionedTransaction: true,
            tokenAAmount: amount, // Swap 0.01 SOL for USDT in this example
            tokenAAddress: from_token_address, // Token to swap for the other, SOL in this case
            tokenBAddress: to_token_address, // USDC address
            maxLamports: 1500000, // Micro lamports for priority fee
            direction: "in", // Swap direction: 'in' or 'out'
            maxRetries: 20,
        }

        /**
         * The RaydiumSwap instance for handling swaps.
         */
        const raydiumSwap = new Swap("https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/", wallet);
        console.log(`Raydium swap initialized`);
        console.log(`Swapping ${swapConfig.tokenAAmount} of ${swapConfig.tokenAAddress} for ${swapConfig.tokenBAddress}...`)

        /**
         * Find pool information for the given token pair.
         */
        console.log("[pool_key]", selectedLP.pool_key)
        const poolInfo = await raydiumSwap.convertPoolInfo(convertKeys(selectedLP.pool_key));
        if (!poolInfo) {
            console.error('Pool info not found');
            return 'Pool info not found';
        } else {
            console.log('Found pool info');
            console.log("[poolInfo]", poolInfo)
        }
        const tx = await raydiumSwap.getSwapTransaction(
            swapConfig.tokenBAddress,
            swapConfig.tokenAAmount,
            poolInfo,
            swapConfig.maxLamports,
            swapConfig.useVersionedTransaction,
            swapConfig.direction
        );

        try {
            setAwaitingSignature(true)
            const signedTransaction = await wallet.signTransaction(tx);
            setAwaitingSignature(false)
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            updateTrade(signature)
            setAwaitingConfirmation(true)
            await connection.confirmTransaction(signature);
            setAwaitingConfirmation(false)
            setToasts(t => [...t, {
                title: 'Swap Confirmed',
                information: `Successfully swapped`,
                icon: 'check',
                link: {
                    text: 'view transaction',
                    uri: `https://solscan.io/tx/${signature}`,
                },
                id: toastId++
            }])
        } catch (error) {
            console.error('Failed to sign or send transaction:', error);
            setAwaitingSignature(false)
            setAwaitingConfirmation(false)
            setToasts(t => [...t, {
                title: 'Swap Failed',
                information: `Your swap did not go through, try again`,
                id: toastId++
            }])
        }
    }

    let isDisabled = false

    if (lpInfos.length === 0) {
        isDisabled = true
    }

    return (
        <div style={{...oldStyles.box, width: isMobile ? '100%' : '400px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{fontWeight: 'bold'}}>
                    You're paying
                </div>
                <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <div style={{fontSize: '12px', color: 'rgba(255, 255, 255, 0.5)'}}>
                        {`${formatToXSignificantDigits(fromAmt, 5)} ${from.symbol}`}
                    </div>
                    <div onClick={() => setFromAmt(`${fullAmt / 2}`)} className={css(styles.miniButton)}>
                        Half
                    </div>
                    <div onClick={() => setFromAmt(`${fullAmt}`)} className={css(styles.miniButton)}>
                        Max
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px', borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.3)', padding: '15px 15px', boxSizing: 'border-box'}}>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer', padding: '10px 15px', fontSize: '14px', fontWeight: 'bold', boxSizing: 'border-box', borderRadius: '15px', backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                    <ImageWithFallback
                        imageUri={from.icon ?? from.image ?? from.uri ?? from.image_uri ?? `${window.location.origin}/missing.png`}
                        fallbackUri={`${window.location.origin}/missing.png`}
                        name={name}
                        size={30}
                    />
                    {from.symbol}
                </div>
                <div style={{textAlign: 'right', display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: '5px'}}>
                    <input value={fromAmt} onChange={updateFromAmount} style={{fontWeight: '900', color: 'white', fontSize: '18px', textAlign: 'right', width: '150px'}} placeholder={`0.00`} />
                    {fromAmt.length > 0 && (
                        <div style={{color: 'rgba(255, 255, 255, 0.4)', fontWeight: '700', fontSize: '14px'}}>
                            {`$${((Number(fromAmt) || 0) * currentFromPrice).toFixed(2)}`}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div style={{width: '100%', margin: '10px 0', height: '1px', backgroundColor: 'rgb(10, 10, 10)', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={switchToAndFrom} style={{cursor: 'pointer', width: '30px', height: '30px', position: 'absolute', border: '3px solid rgb(10, 10, 10)', display: 'flex', alignItems: 'center', justifyContent: 'center', top: '-15px', borderRadius: '50%', backgroundColor: 'rgb(55, 55, 65)'}}>
                        <img src={`${window.location.origin}/swap.png`} height={26} />
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{fontWeight: 'bold'}}>
                    You receive
                </div>
                <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <div style={{fontSize: '12px', color: 'rgba(255, 255, 255, 0.5)'}}>
                        {`${formatToXSignificantDigits((Number(toAmt) || 0), 5)} ${to.symbol}`}
                    </div>
                </div>
            </div>
            <div style={{width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', height: '70px', borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.3)', padding: '15px 15px', boxSizing: 'border-box'}}>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer', padding: '10px 15px', fontSize: '14px', fontWeight: 'bold', boxSizing: 'border-box', borderRadius: '15px', backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
                    <ImageWithFallback
                        imageUri={to.icon ?? to.image ?? to.uri ?? to.image_uri ?? `${window.location.origin}/missing.png`}
                        fallbackUri={`${window.location.origin}/missing.png`}
                        name={name}
                        size={30}
                    />
                    {to.symbol}
                </div>
                <div style={{textAlign: 'right', display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: '5px'}}>
                    <input value={toAmt} onChange={(e) => updateToAmount(e.target.value)} style={{fontWeight: '900', color: 'white', fontSize: '18px', textAlign: 'right', width: '150px'}} placeholder={`0.00`} />
                    {toAmt.length > 0 && (
                        <div style={{color: 'rgba(255, 255, 255, 0.4)', fontWeight: '700', fontSize: '14px'}}>
                            {`~$${(currentToPrice * (Number(toAmt) || 0)).toFixed(2)}`}
                        </div>
                    )}
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px'}}>
                {!isDisabled && (
                    <div style={{flexGrow: 1}} onClick={() => testSwap(wallet, from.token_address, to.token_address, Number(fromAmt), setToasts)}
                         className={css(styles.primaryButton)}>
                        Swap
                    </div>)}

                {isDisabled && (
                    <div style={{flexGrow: 1}}
                         className={css(styles.disabledButton)}>
                        Swap
                    </div>)}
                {
                    awaitingSignature && (
                        <div style={{flexGrow: 1}} className={css(styles.container)}>
                            <div style={{fontWeight: 900, color: 'white', fontSize: '20px', display: 'flex', gap: '10px', alignItems: 'center'}}>
                                Awaiting Signature
                                <div className={"lds-facebook"} style={{opacity: '0.3', width: '40px', height: '40px', color: 'white'}}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div className={css(styles.divider)} />
                            <div>
                                Please confirm the transaction
                            </div>
                        </div>
                    )
                }
                {
                    awaitingConfirmation && (
                        <div style={{flexGrow: 1}} className={css(styles.container)}>
                            <div style={{fontWeight: 900, color: 'white', fontSize: '20px', display: 'flex', gap: '10px', alignItems: 'center'}}>
                                Processing
                                <div className={"lds-facebook"} style={{opacity: '0.3', width: '40px', height: '40px', color: 'white'}}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div className={css(styles.divider)} />
                            <div>
                                Please wait, your transaction is being processed
                            </div>
                        </div>
                    )
                }

                <div style={{position: 'relative'}}>
                    {!!selectedLP && (
                        <div onClick={() => setShowExchangeDropdown(true)} className={css(styles.exchangeButton)}>
                            {`via ${selectedLP?.liquidity_pool?.name}`}
                        </div>
                    )}
                    { showExchangeDropdown && (
                        <div ref={modalRef} style={{position: 'absolute', zIndex: 2, bottom: '40px', width: '200px', right: '0px'}}>
                            <div style={{padding: '20px', borderRadius: '20px', width: '100%', backgroundColor: '#0f1018', border: '1px solid #42444f', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                {lpInfos.map((lpInfo) => (
                                        <div onClick={() => {
                                            setSelectedLP(lpInfo)
                                            setShowExchangeDropdown(false)
                                        }} className={css(styles.exchangeOption)}>
                                            {lpInfo.liquidity_pool.name}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
            {renderToasts()}
        </div>
    )
}

export default Swapper