import moment from "moment/moment";
import {commafy, formatToThreeSignificantDigits, formatToXSignificantDigits} from "./utils";
import React, {useEffect, useState} from "react";

const tradeGridTemplateColumns = '100px 70px minmax(85px, 1fr) minmax(105px, 1fr) minmax(80px, 1fr) 50px'

const formatRelativeTime = (timeAgo) => {
    const now = moment();
    const diffSeconds = now.diff(timeAgo, 'seconds');
    const diffMinutes = now.diff(timeAgo, 'minutes');
    const diffHours = now.diff(timeAgo, 'hours');

    if (diffSeconds < 60) {
        return `${diffSeconds}s ago`;
    } else if (diffMinutes < 60) {
        return `${diffMinutes}m ago`;
    } else if (diffHours < 24) {
        return `${diffHours}h ago`;
    } else {
        return `${now.diff(timeAgo, 'days')}d ago`;
    }
}
const Trade = ({trade, topVolume}) => {
    const {created_at, volume_in_usd, sol_amount, signature, token_amount, trade_type, price_of_token_in_usd} = trade
    const [timeAgo, setTimeAgo] = useState(formatRelativeTime(moment(created_at)))

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(formatRelativeTime(moment(created_at)));
        }, 1000);  // Updates every second

        return () => {
            clearInterval(interval);  // Clear the interval when the component unloads
        };
    }, [created_at])

    const hasFadeIn = moment(created_at).isAfter(moment().subtract(10, 'seconds'))

    return (
        <div className={hasFadeIn && "fade-in-slide-in"} style={{display: 'grid',
            gridTemplateColumns: tradeGridTemplateColumns,
            fontSize: '16px', position: 'relative', boxSizing: 'border-box', padding: '5px 15px', zIndex: 2, alignItems: 'center'}}>
            <div style={{width: '100px', fontSize: '12px', zIndex: 2}}>
                {timeAgo}
            </div>
            <div style={{fontWeight: '700', color: trade_type === "buy" ? "#57cc66" : "#cb5850", zIndex: 2}}>
                {trade_type === "sell" ? "Sell" : "Buy"}
            </div>
            <div style={{fontWeight: '700', zIndex: 2}}>
                {`$${Number(volume_in_usd).toFixed(2)}`}
            </div>
            <div style={{fontWeight: '700', zIndex: 2}}>
                {`$${formatToXSignificantDigits(Number(price_of_token_in_usd), 5)}`}
            </div>
            <div style={{fontWeight: '700', zIndex: 2}}>
                {`${formatToThreeSignificantDigits(Number(token_amount))}`}
            </div>
            <div style={{textAlign: 'right', zIndex: 2}}>
                <a target="_blank" href={`https://solscan.io/tx/${signature}`}>
                    <img style={{opacity: 0.3}} src={`${window.location.origin}/link.png`} height={16} />
                </a>
            </div>
            <div style={{position: 'absolute', marginLeft: '-5px', width: `${(Number(volume_in_usd)/topVolume)*100}%`, height: '35px', opacity: 0.1, backgroundColor: trade_type === "buy" ? "#57cc66" : "#cb5850", borderRadius: '0px'}}>

            </div>
        </div>
    )
}

export default Trade