import { Connection, PublicKey, Transaction, VersionedTransaction, TransactionMessage } from '@solana/web3.js'
import {
    Liquidity,
    LiquidityPoolKeys,
    jsonInfo2PoolKeys,
    LiquidityPoolJsonInfo,
    TokenAccount,
    Token,
    TokenAmount,
    TOKEN_PROGRAM_ID,
    Percent,
    SPL_ACCOUNT_LAYOUT,
} from '@raydium-io/raydium-sdk'
import {origin} from "./utils";

/**
 * Class representing a Raydium Swap operation.
 */

export const getPoolKeys = async (quoteMint, baseMint) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/pool_keys/${quoteMint}:${baseMint}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

function snakeToCamel(snakeStr) {
    // Convert snake_case to camelCase
    return snakeStr.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

export function convertKeys(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            camelCaseObj[snakeToCamel(key)] = obj[key];
        }
    }
    return camelCaseObj;
}

class Swap {
    allPoolKeysJson //: LiquidityPoolJsonInfo[]
    connection //: Connection
    wallet //: Wallet

    /**
     * Create a RaydiumSwap instance.
     * @param {string} RPC_URL - The RPC URL for connecting to the Solana blockchain.
     * @param {string} WALLET_PRIVATE_KEY - The private key of the wallet in base58 format.
     */
    constructor(RPC_URL, wallet) {
        this.connection = new Connection("https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/"
            , {
                wsEndpoint: "wss://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/",
            })
        this.wallet = wallet
    }

    /**
     * Loads all the pool keys available from a JSON configuration file.
     * @async
     * @returns {Promise<void>}
     */
    async loadPoolKeys(liquidityFile) {
        // const liquidityJsonResp = await fetch(liquidityFile);
        // if (!liquidityJsonResp.ok) return
        // const liquidityJson = (await liquidityJsonResp.json())
        // const allPoolKeysJson = [...(liquidityJson?.official ?? []), ...(liquidityJson?.unOfficial ?? [])]



        // let allPoolKeysJson = [{"id":"58oQChx4yWmvKdwLLZzBi4ChoCc2fqCUWBkwMihLYQo2","baseMint":"So11111111111111111111111111111111111111112","quoteMint":"EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v","lpMint":"8HoQnePLqPj4M7PUDzfw8e3Ymdwgc7NLGnaTUapubyvu","baseDecimals":9,"quoteDecimals":6,"lpDecimals":9,"version":4,"programId":"675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8","authority":"5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1","openOrders":"HmiHHzq4Fym9e1D4qzLS6LDDM3tNsCTBPDWHTLZ763jY","targetOrders":"CZza3Ej4Mc58MnxWA385itCC9jCo3L1D7zc3LKy1bZMR","baseVault":"DQyrAcCrDXQ7NeoqGgDCZwBvWDcYmFCjSb9JtteuvPpz","quoteVault":"HLmqeL62xR1QoZ1HKKbXRrdN1p3phKpxRMb2VVopvBBz","withdrawQueue":"11111111111111111111111111111111","lpVault":"11111111111111111111111111111111","marketVersion":4,"marketProgramId":"srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX","marketId":"8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6","marketAuthority":"CTz5UMLQm2SRWHzQnU62Pi4yJqbNGjgRBHqqp6oDHfF7","marketBaseVault":"CKxTHwM9fPMRRvZmFnFoqKNd9pQR21c5Aq9bh5h9oghX","marketQuoteVault":"6A5NHCj1yF6urc9wZNe6Bcjj4LVszQNj5DwAWG97yzMu","marketBids":"5jWUncPNBMZJ3sTHKmMLszypVkoRK6bfEQMQUHweeQnh","marketAsks":"EaXdHx7x3mdGA38j5RSmKYSXMzAFzzUXCLNBEDXDn1d5","marketEventQueue":"8CvwxZ9Db6XbLD46NZwwmVDZZRDy7eydFcAGkXKh9axa","lookupTableAccount":"3q8sZGGpPESLxurJjNmr7s7wcKS5RPCCHMagbuHP9U2W"}]

    }

    /**
     * Finds pool information for the given token pair.
     * @param {string} mintA - The mint address of the first token.
     * @param {string} mintB - The mint address of the second token.
     * @returns {LiquidityPoolKeys | null} The liquidity pool keys if found, otherwise null.
     */
    async findPoolInfoForTokens(mintA, mintB) {

        const poolData = await getPoolKeys(mintA, mintB)
        const data = convertKeys(poolData.pool_keys[0])

        if (!data) return null

        return jsonInfo2PoolKeys(data)
    }

    convertPoolInfo(poolKeys) {
        if (!poolKeys) return null

        return jsonInfo2PoolKeys(poolKeys)
    }

    async sendVersionedTransaction(tx, maxRetries) {
        const txid = await this.connection.sendTransaction(tx, {
            skipPreflight: true,
            maxRetries: maxRetries,
        })

        return txid
    }

    /**
     * Retrieves token accounts owned by the wallet.
     * @async
     * @returns {Promise<TokenAccount[]>} An array of token accounts.
     */
    async getOwnerTokenAccounts() {
        const walletTokenAccount = await this.connection.getTokenAccountsByOwner(this.wallet.publicKey, {
            programId: TOKEN_PROGRAM_ID,
        })

        return walletTokenAccount.value.map((i) => ({
            pubkey: i.pubkey,
            programId: i.account.owner,
            accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data),
        }))
    }

    /**
     * Builds a swap transaction.
     * @async
     * @param {string} toToken - The mint address of the token to receive.
     * @param {number} amount - The amount of the token to swap.
     * @param {LiquidityPoolKeys} poolKeys - The liquidity pool keys.
     * @param {number} [maxLamports=100000] - The maximum lamports to use for transaction fees.
     * @param {boolean} [useVersionedTransaction=true] - Whether to use a versioned transaction.
     * @param {'in' | 'out'} [fixedSide='in'] - The fixed side of the swap ('in' or 'out').
     * @returns {Promise<Transaction | VersionedTransaction>} The constructed swap transaction.
     */
    async getSwapTransaction(
        toToken,
        // fromToken: string,
        amount,
        poolKeys, //: LiquidityPoolKeys,
        maxLamports = 100000,
        useVersionedTransaction = true,
        fixedSide= 'in'
    ) {
        const directionIn = poolKeys.quoteMint.toString() == toToken
        const { minAmountOut, amountIn } = await this.calcAmountOut(poolKeys, amount, directionIn)
        console.log({ minAmountOut, amountIn });
        const userTokenAccounts = await this.getOwnerTokenAccounts()
        const swapTransaction = await Liquidity.makeSwapInstructionSimple({
            connection: this.connection,
            makeTxVersion: useVersionedTransaction ? 0 : 1,
            poolKeys: {
                ...poolKeys,
            },
            userKeys: {
                tokenAccounts: userTokenAccounts,
                owner: this.wallet.publicKey,
            },
            amountIn: amountIn,
            amountOut: minAmountOut,
            fixedSide: fixedSide,
            config: {
                bypassAssociatedCheck: false,
            },
            computeBudgetConfig: {
                microLamports: maxLamports,
            },
        })

        const recentBlockhashForSwap = await this.connection.getLatestBlockhash()
        const instructions = swapTransaction.innerTransactions[0].instructions.filter(Boolean)

        if (useVersionedTransaction) {
            const versionedTransaction = new VersionedTransaction(
                new TransactionMessage({
                    payerKey: this.wallet.publicKey,
                    recentBlockhash: recentBlockhashForSwap.blockhash,
                    instructions: instructions,
                }).compileToV0Message()
            )

            // versionedTransaction.sign([this.wallet.payer])

            return versionedTransaction
        }

        const legacyTransaction = new Transaction({
            blockhash: recentBlockhashForSwap.blockhash,
            lastValidBlockHeight: recentBlockhashForSwap.lastValidBlockHeight,
            feePayer: this.wallet.publicKey,
        })

        legacyTransaction.add(...instructions)

        return legacyTransaction
    }


    /**
     * Gets a token account by owner and mint address.
     * @param {PublicKey} mint - The mint address of the token.
     * @returns {TokenAccount} The token account.
     */
    getTokenAccountByOwnerAndMint(mint) { // PublicKey
        return {
            programId: TOKEN_PROGRAM_ID,
            pubkey: PublicKey.default,
            accountInfo: {
                mint: mint,
                amount: 0,
            },
        }
    }

    /**
     * Calculates the amount out for a swap.
     * @async
     * @param {LiquidityPoolKeys} poolKeys - The liquidity pool keys.
     * @param {number} rawAmountIn - The raw amount of the input token.
     * @param {boolean} swapInDirection - The direction of the swap (true for in, false for out).
     * @returns {Promise<Object>} The swap calculation result.
     */
    async calcAmountOut(poolKeys, rawAmountIn, swapInDirection) {
        const poolInfo = await Liquidity.fetchInfo({ connection: this.connection, poolKeys })

        let currencyInMint = poolKeys.baseMint
        let currencyInDecimals = poolInfo.baseDecimals
        let currencyOutMint = poolKeys.quoteMint
        let currencyOutDecimals = poolInfo.quoteDecimals

        if (!swapInDirection) {
            currencyInMint = poolKeys.quoteMint
            currencyInDecimals = poolInfo.quoteDecimals
            currencyOutMint = poolKeys.baseMint
            currencyOutDecimals = poolInfo.baseDecimals
        }

        const currencyIn = new Token(TOKEN_PROGRAM_ID, currencyInMint, currencyInDecimals)
        const amountIn = new TokenAmount(currencyIn, rawAmountIn, false)
        const currencyOut = new Token(TOKEN_PROGRAM_ID, currencyOutMint, currencyOutDecimals)
        const slippage = new Percent(5, 100) // 5% slippage

        const { amountOut, minAmountOut, currentPrice, executionPrice, priceImpact, fee } = Liquidity.computeAmountOut({
            poolKeys,
            poolInfo,
            amountIn,
            currencyOut,
            slippage,
        })

        return {
            amountIn,
            amountOut,
            minAmountOut,
            currentPrice,
            executionPrice,
            priceImpact,
            fee,
        }
    }
}

export default Swap