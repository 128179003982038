import React, {useEffect, useState} from "react";
import TradingViewWidget from "./TradingViewWidget";
import oldStyles from "./oldStyles";

const LaunchToken = () => {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '50px'}}>
            <div style={oldStyles.box}>
                Launch Token
            </div>
        </div>
    )
}

export default LaunchToken