class WebsocketService {
    constructor(url) {
        this.url = url;
        this.ws = null;
        this.connected = false;
        this.connecting = false;
        this.messageFns = []
    }

    addHandlers(openFn, messageFn, closeFn) {
        this.openFn = openFn
        this.messageFn = messageFn
        this.closeFn = closeFn
    }

    connect() {
        if (!this.connecting && !this.connected) {
            this.connecting = true
            this.ws = new WebSocket(this.url);

            this.ws.onopen = () => {
                console.log("WebSocket Connected");
                this.openFn(this.ws)
                this.connecting = false
                this.connected = true
            };

            this.ws.onmessage = (event) => {
                console.log("Received message:", event.data);
                this.messageFn(event)
                this.messageFns.forEach(mfn => {
                    mfn(event)
                })
            };

            this.ws.onerror = (event) => {
                console.error("WebSocket Error:", event);
                this.ws.close();
            };

            this.ws.onclose = (event) => {
                console.log("WebSocket Disconnected", event);
                this.closeFn()
                this.connecting = false
                this.connected = false
                this.handleReconnect();
            };
        }
    }

    addListener(fn) {
        this.messageFns.push(fn)
    }

    handleReconnect() {
        // Exponential backoff or a simple timeout
        setTimeout(() => {
            console.log("Attempting to reconnect...");
            this.connect();
        }, 5000); // Reconnect after 5 seconds
    }

    sendMessage(message) {
        if (this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(message);
        } else {
            console.error("WebSocket is not open. Message not sent:", message);
        }
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }
}

export default new WebsocketService(process.env.NODE_ENV === "development" ? "ws://localhost:443" : 'wss://socket.flakeswap.com')