import React, {useContext, useEffect, useState} from "react";
import {useWallet} from "@solana/wallet-adapter-react";
import {PhantomWalletName} from "@solana/wallet-adapter-phantom";
import {css, StyleSheet} from "aphrodite";
import Search from "./Search";
import HamburgerMenu from "./HamburgerMenu";
import {commafy, origin} from "./utils";
import AppContext from "./AppContext";

const styles = StyleSheet.create({
    walletButton: {
        padding: '10px 20px',
        borderRadius: '10px',
        width: '180px',
        textAlign: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255,255,255,.05)',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(255,255,255,.1)',
            border: '1px solid rgba(255,255,255,.1)',
        },
    },
    primaryButton: {
        background: 'rgb(32 129 226)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '900',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgb(69, 145, 222)',
        },
    },
    navButton: {
        color: 'rgb(106, 106, 109)',
        textDecoration: 'none',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            color: 'rgb(255, 255, 255)',
        },
    },
    active: {
        color: '#FFF'
    },
    flakeCount: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255,255,255,.05)',
        padding: '8px',
        borderRadius: '10px',
        transition: '0.3s all',
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(255,255,255,.1)',
            border: '1px solid rgba(255,255,255,.1)',
        },
    },
})

const createReferral = ({referrer, referee}) => {
    try {
        let body = {
            referrer,
            referee
        }
        const headers = {'Content-Type': 'application/json'}
        fetch(`${origin}/referrals`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from authenticate', res)
                if (res.success) {

                } else {

                }
            })
            .catch(err => {

            })
    } catch(e) {
        return Promise.resolve({})
    }
}

const getAccount = async ({ address }) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/accounts/${address}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

function getQueryParamValue(key) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(key);
}

const getNewChallenge = async (publicKey) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/challenge?public_key=${publicKey}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();  // This should return the challenge
    } catch (err) {
        console.error("Failed to fetch new challenge:", err);
        return Promise.resolve({});  // Return an empty object on error
    }
};

const verifySignature = async (publicKey, signature, challenge) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const body = JSON.stringify({
            public_key: publicKey,
            signature: signature,
            challenge: challenge
        });
        const response = await fetch(`${origin}/verify`, {
            method: "POST",
            headers: headers,
            body: body
        });
        return response.json();  // This should return the JWT token if the verification is successful
    } catch (err) {
        console.error("Failed to verify signature:", err);
        return Promise.resolve({});
    }
};

const startAuthentication = async (wallet) => {
    // Get new challenge
    const challengeResponse = await getNewChallenge(wallet.publicKey);
    if (!challengeResponse.challenge) {
        console.error("No challenge received");
        return;
    }
    const challenge = challengeResponse.challenge;

    try {
        const encodedMessage = new TextEncoder().encode(challenge);
        const signatureBytes = await wallet.signMessage(encodedMessage, "utf8");
        const signatureBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(signatureBytes)))
        console.log('Signature:', signatureBase64);

        // Verify the signature
        const verifyResponse = await verifySignature(wallet.publicKey, signatureBase64, challenge);
        if (verifyResponse.token) {
            console.log("Authenticated successfully. JWT:", verifyResponse.token);
        } else {
            console.error("Authentication failed");
        }
    } catch (error) {
        console.error('Error signing message:', error);
    }
};

const SolanaSignMessage = () => {
    const wallet = useWallet();

    const signMessage = async (message) => {
        if (!wallet.connected) {
            console.log('Connect to a wallet first.');
            return;
        }
        await startAuthentication(wallet)
    };

    return (
        <div>
            <button onClick={() => signMessage("Hello from Solana!")}>Sign Message</button>
        </div>
    );
}

const Header = ({page, setPage, setCoinSelected}) => {
    const {isMobile, account, setAccount, setShowModal} = useContext(AppContext)

    const [showRewardInfo, setShowRewardInfo] = useState(false)
    const { publicKey, connect, disconnect, select } = useWallet();
    const [searchFocused, setSearchFocused] = useState(false)

    const address = publicKey?.toBase58() ?? ""

    useEffect(() => {
        const referrer = getQueryParamValue('r');
        if (referrer && address) {
            createReferral({referrer, referee: address})
        }
    }, [address])

    const findAccount = async({address}) => {
        const res = await getAccount({address})
        setAccount(res?.account ?? {})
    }

    useEffect(() => {
        if (address) {
            findAccount({address})
        }
    }, [address])

    const handleConnect = async () => {
        try {
            select(PhantomWalletName)
            await connect();
        } catch (e) {
            console.error(e);
        }
    };
    const handleDisconnect = async() => {
        try {
            await disconnect();
        } catch (e) {
            console.error(e);
        }
    };

    if (isMobile) {
        return (
            <div style={{position: 'relative', width: '100%', flexBasis: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <a href={"/"} style={{fontSize: '30px', color: '#4fff69', display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                    <img src={`${window.location.origin}/flake.png`} height={40} />
                </a>
                <Search onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} searchFocused={searchFocused} setCoinSelected={setCoinSelected} />
                <HamburgerMenu setPage={setPage} />
            </div>
        )
    }

    return (
        <div style={{position: 'relative', width: '100%', flexBasis: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', gap: '30px', fontWeight: '700', alignItems: 'center'}}>
                <a href={"/"} style={{fontSize: '30px', color: '#4fff69', display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                    <img src={`${window.location.origin}/flake.png`} height={40} />
                </a>
                <div style={{display: 'flex', gap: '30px'}}>
                    <div onClick={() => setPage('dashboard')} className={`${css(styles.navButton)} ${page === 'dashboard' ? css(styles.active) : {}}`}>
                        Dashboard
                    </div>
                    <div onClick={() => setPage('airdrop')} className={`${css(styles.navButton)} ${page === 'airdrop' ? css(styles.active) : {}}`} style={page === 'airdrop' ? {color: '#FFF'} : {}}>
                        Airdrop
                    </div>
                    <a href={'https://docs.flakeswap.com/'} target={"_blank"} className={`${css(styles.navButton)}`}>
                        Docs
                    </a>
                    {/*<SolanaSignMessage />*/}
                </div>
            </div>
            <Search onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} searchFocused={searchFocused} setCoinSelected={setCoinSelected} />
            <div style={{display: 'flex', gap: '20px', fontWeight: '900', alignItems: 'center'}}>
                {/*<div style={{fontWeight: '400', color: 'rgb(106, 106, 109)'}}>*/}
                {/*  {address ? address : "Not Connected"}*/}
                {/*</div>*/}
                { account?.airdrop_available && !account?.airdrop_claimed && (
                    <div onClick={() => setShowModal(true)} className={css(styles.primaryButton)}>
                        Claim Airdrop
                    </div>
                )}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <div onMouseOver={() => setShowRewardInfo(true)} onMouseOut={() => setShowRewardInfo(false)} className={css(styles.flakeCount)}>
                        <img src={`${window.location.origin}/flake.png`} height={18} />
                        <div style={{color: '#bedcef', fontSize: '16px', fontWeight: '900'}}>
                            {commafy(Math.floor(account.flakes ?? 0))}
                        </div>
                        { showRewardInfo && account.bonus_available > 0 &&
                            (<div style={{position: 'absolute', boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px', fontSize: '14px', top: '46px', left: '-110px', display: 'flex', gap: '10px', flexDirection: 'column', backgroundColor: '#0f1018', border: '1px solid ' +
                                    '#35353f', padding: '20px',borderRadius: '10px', transition: '0.3s all',}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', fontWeight: '900', fontSize: '16px'}}>
                                    <div style={{fontWeight: '400', color: 'rgba(255, 255, 255, 0.7)'}}>
                                        Flake Progress
                                    </div>
                                    <div style={{display: 'flex', gap: '5px', fontSize: '14px'}}>
                                        {commafy(Math.floor(account.bonus_available))}
                                        <img src={`${window.location.origin}/flake.png`} height={14} />
                                        Bonus
                                    </div>
                                </div>
                                <div style={{backgroundColor: '#000', width: '300px', height: '16px', borderRadius: '20px'}}>
                                    <div style={{width: `${Math.floor(account.bonus_earned / account.bonus_available)}%`, borderRadius: '20px', height: '16px', background: 'linear-gradient(350deg, rgba(79,214,255,1) 0%, rgba(48,228,244,1) 19%, rgba(59,175,237,1) 100%)'}} />
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', fontWeight: '900', fontSize: '16px'}}>
                                    <div>
                                        {`${commafy(Math.floor(account.bonus_earned))} / ${commafy(Math.floor(account.bonus_available))}`}
                                    </div>
                                    <div>
                                        {`${Math.floor(account.bonus_earned / account.bonus_available)}%`}
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                <div onClick={address ? handleDisconnect :handleConnect} className={css(styles.walletButton)}>
                    {address ? 'Disconnect Wallet' : 'Connect Wallet'}
                </div>
            </div>
            {searchFocused && (
                <div style={{position: 'absolute', width: '100vw', left: '-20px', height: 'calc(100svh - 80px)', zIndex: 20, top: '60px', backdropFilter: 'blur(5px)'}}>

                </div>
            )}
        </div>
    )
}

export default Header