import React, {useContext, useEffect, useState} from 'react'
import {css, StyleSheet} from "aphrodite";
import {commafy, origin} from "./utils";
import airdrop from "./Airdrop";
import {useWallet} from "@solana/wallet-adapter-react";
import AppContext from "./AppContext";

const styles = StyleSheet.create({
    primaryButton: {
        background: 'rgb(32 129 226)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '900',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgb(69, 145, 222)',
        },
    },
})

const getAirdrop = async (walletAddress) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/airdrops/${walletAddress}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const claimAirdrop = async (walletAddress) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/airdrops/claim?address=${walletAddress}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};
const ClaimModal = ({closeModal}) => {
    const [claimed, setClaimed] = useState(false)
    const [airdrop, setAirdrop] = useState({})
    const [loading, setLoading] = useState(true)
    const { publicKey } = useWallet()
    const {setAccount} = useContext(AppContext)
    const handleAirdrop = async(address) => {
        const airdropRes = await getAirdrop(address)

        const {airdrop} = airdropRes

        if (airdrop) {
            setAirdrop(airdrop)
            setClaimed(airdrop.claimed)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (publicKey) {
            console.log("reee", publicKey?.toBase58())
            handleAirdrop(publicKey?.toBase58() ?? "")
        }
    }, [publicKey])
    const handleClaim = async() => {
        if (publicKey) {
            const claimResponse = await claimAirdrop(publicKey?.toBase58() ?? "")

            if (claimResponse.account) {
                setAccount(claimResponse.account)
            }

            setClaimed(true)
        }
    }

    const {amount, bonus_amount, volume, trades} = airdrop

    const claimPossible = amount > 0

    if (loading) {
        return (
            <div style={{width: '100%', height: 'calc(-80px + 100svh)', position: 'absolute'}}>
                <div style={{position: 'relative', height: '100%', width: '100%'}}>
                    <div onClick={closeModal} style={{position: 'absolute', width: '100vw', height: 'calc(100svh - 80px)', zIndex: 20, backdropFilter: 'blur(5px)'}} />
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{background: '#0f1018', border: '1px solid #42444f', borderRadius: '10px', zIndex: 30}}>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '40px', padding: '30px 50px', background: 'linear-gradient(165deg,rgba(118,120,136,0.1) 3.89%,rgba(118,120,136,0) 66.42%)'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>
                                    Checking for airdrop allocation...
                                    <button onClick={closeModal} className={css(styles.primaryButton)}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!claimPossible) {
        return (
            <div style={{width: '100%', height: 'calc(-80px + 100svh)', position: 'absolute'}}>
                <div style={{position: 'relative', height: '100%', width: '100%'}}>
                    <div onClick={closeModal} style={{position: 'absolute', width: '100vw', height: 'calc(100svh - 80px)', zIndex: 20, backdropFilter: 'blur(5px)'}} />
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{background: '#0f1018', border: '1px solid #42444f', borderRadius: '10px', zIndex: 30}}>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '40px', padding: '30px 50px', background: 'linear-gradient(165deg,rgba(118,120,136,0.1) 3.89%,rgba(118,120,136,0) 66.42%)'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>
                                    This wallet did not qualify for the airdrop.
                                    <button onClick={closeModal} className={css(styles.primaryButton)}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: '100%', height: 'calc(-80px + 100svh)', position: 'absolute'}}>
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
                <div onClick={closeModal} style={{position: 'absolute', width: '100vw', height: 'calc(100svh - 80px)', zIndex: 20, backdropFilter: 'blur(5px)'}} />
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{background: '#0f1018', border: '1px solid #42444f', borderRadius: '10px', zIndex: 30}}>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '40px', padding: '30px 50px', background: 'linear-gradient(165deg,rgba(118,120,136,0.1) 3.89%,rgba(118,120,136,0) 66.42%)'}}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
                                <div style={{color: 'rgba(255, 255, 255, 0.6)', fontSize: '18px'}}>
                                    Claim Now
                                </div>
                                <div style={{fontWeight: '900', fontSize: '50px'}}>
                                    {amount}
                                </div>
                            </div>
                            <div>
                                <img style={{animation: 'rotation 20s infinite linear'}} src={`${window.location.origin}/flake.png`} height={120} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
                                    <div style={{color: 'rgba(255, 255, 255, 0.6)', fontSize: '12px'}}>
                                        Trades
                                    </div>
                                    <div style={{fontWeight: 900, fontSize: '30px'}}>
                                        {trades}
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center'}}>
                                    <div style={{color: 'rgba(255, 255, 255, 0.6)', fontSize: '12px'}}>
                                        Volume
                                    </div>
                                    <div style={{fontWeight: 900, fontSize: '30px'}}>
                                        {`$${commafy(Number(volume).toFixed(2))}`}
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', gap: '100px', color: 'rgba(255, 255, 255, 0.6)'}}>
                                    <div>
                                        Flakes Claimable
                                    </div>
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        {amount}
                                        <img src={`${window.location.origin}/flake.png`} height={16} />
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', gap: '100px', color: 'rgba(255, 255, 255, 0.6)'}}>
                                    <div>
                                        Potential Bonus Flakes
                                    </div>
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        {bonus_amount}
                                        <img src={`${window.location.origin}/flake.png`} height={16} />
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', gap: '100px', fontWeight: '700', fontSize: '20px'}}>
                                    <div>
                                        Total
                                    </div>
                                    <div style={{display: 'flex', gap: '10px', fontWeight: '900'}}>
                                        {amount + bonus_amount}
                                        <img src={`${window.location.origin}/flake.png`} height={16} />
                                    </div>
                                </div>
                            </div>
                            { !claimed && (
                                <button onClick={handleClaim} className={css(styles.primaryButton)}>
                                    Claim
                                </button>
                            )}
                            {
                                claimed && (
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>
                                        You have successfully claimed your Flakes!
                                        <button onClick={closeModal} className={css(styles.primaryButton)}>
                                            Close
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClaimModal