import React, {useContext, useEffect, useState} from "react";
import oldStyles from "./oldStyles";
import TokensHeader from "./TokensHeader";
import Token from "./Token";
import {origin} from "./utils";
import AppContext from "./AppContext";

const getTokens = async () => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/tokens`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const Tokens = ({setCoinSelected}) => {
    const [coins, setCoins] = useState([])
    const {isMobile, socket, socketOpen} = useContext(AppContext)
    const [subscribed, setSubscribed] = useState(false)

    useEffect(() => {
        findTokens()
    }, [])

    useEffect(() => {
        if (socketOpen && coins.length > 0 && !subscribed) {
            setSubscribed(true)

            const tokensToAdd = {
                add_symbols: coins.map(token => token.token_address)
            }

            socket.send(JSON.stringify(tokensToAdd))
        }

        if (subscribed && !socketOpen) {
            setSubscribed(false)
        }
    }, [socketOpen, coins, subscribed, setSubscribed])

    const findTokens = async() => {
        const res = await getTokens()

        setCoins(res?.tokens || [])
        // setTotalVolume(`$${res?.volume?.volume_usd ?? '0.00'}`)
    }


    return (
        <div style={{...oldStyles.box, scrollbarWidth: 'none', flexGrow: 1, overflow: 'scroll', minWidth: isMobile ? undefined : '600px', width: isMobile ? 'calc(100svw - 40px)' : undefined}}>
            <div style={{fontWeight: '700', fontSize: '18px', marginBottom: '-10px'}}>
                <TokensHeader />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '5px', width: '100%', overflowY: 'auto', scrollbarWidth: 'none'}}>
                {coins.map((coin, idx) => <Token key={idx} handleClick={() => setCoinSelected(coin)} coin={coin} />)}
            </div>
        </div>
    )
}

export default Tokens