import React, {useEffect, useState} from "react";
import {useWallet} from "@solana/wallet-adapter-react";
import oldStyles from "./oldStyles";

const getVolume = async ({ address }) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/volumes/${address}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const getReferrals = async ({ address }) => {
    try {
        // const token = document.querySelector('[name=csrf-token]')?.content;
        const headers = { 'Content-Type': 'application/json'}//, 'X-CSRF-TOKEN': token };
        const response = await fetch(`${origin}/referrals/${address}`, {
            method: "GET",
            headers: headers,
        });
        return response.json();
    } catch (err) {
        return Promise.resolve({})
    }
};

const AirdropWidget = () => {
    const [totalVolume, setTotalVolume] = useState("$0.00")
    const [totalReferrals, setTotalReferrals] = useState(0)
    const { publicKey } = useWallet();
    const address = publicKey?.toBase58() ?? ""

    const findVolume = async({address}) => {
        const res = await getVolume({address})
        console.log("vol", res)
        setTotalVolume(`$${res?.volume?.volume_usd ?? '0.00'}`)
    }

    const findReferrals = async({address}) => {
        const res = await getReferrals({address})
        setTotalReferrals(res?.referrals ?? 0)
    }

    useEffect(() => {
        if (address) {
            findVolume({address})
            findReferrals({address})
        } else {
            setTotalVolume("$0.00")
            setTotalReferrals(0)
        }
    }, [address])

    return (
        <div style={{...oldStyles.box, height: '200px'}}>
            <div style={{fontWeight: '700', fontSize: '18px', textAlign: 'center'}}>
                Flake Airdrop
            </div>
            <div style={{justifySelf: 'center', display: 'flex', justifyContent: 'space-around'}}>
                <div style={{display: 'inline-flex', justifySelf: 'center', gap: '30px'}}>
                    <div style={{display: 'flex', gap: '15px', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{color: 'rgb(106, 106, 109)'}}>
                            Your Volume
                        </div>
                        <div style={{fontWeight: 'bold', fontSize: '30px'}}>
                            {totalVolume}
                        </div>
                    </div>
                    <div style={{display: 'flex', gap: '15px', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{color: 'rgb(106, 106, 109)'}}>
                            Referrals
                        </div>
                        <div style={{fontWeight: 'bold', fontSize: '30px'}}>
                            {totalReferrals}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{color: 'rgb(106, 106, 109)', fontSize: '12px', display: 'flex', flexDirection: 'column', gap: '5px'}}>
                <div>
                    * Volume & Referrals will increase your airdrop allocation
                </div>
                <div>
                    * Wash trades / sybils will be disqualified
                </div>
                <div>
                    * Only trades on Flakeswap count towards airdrop volume
                </div>
            </div>
        </div>
    )
}

export default AirdropWidget