import React from "react";

const tokenGridTemplateColumns = '200px 120px minmax(40px, 1fr) minmax(40px, 1fr) minmax(40px, 1fr)'
const TokensHeader = () => {
    return (
        <div style={{display: 'grid',gridTemplateColumns: tokenGridTemplateColumns, overflow: 'scroll', scrollbarWidth: 'none', fontSize: '16px', position: 'relative', padding: '5px 15px', color: 'rgba(255, 255, 255, 0.8)'}}>
            <div style={{display: 'flex', alignItems: 'center', width: '240px', gap: '10px', overflow: 'hidden'}}>
                Token
            </div>
            <div style={{color: 'white', fontSize: '14px', paddingLeft: '10px', textAlign: 'right'}}>
                Price
            </div>
            <div style={{color: 'white', fontSize: '14px', textAlign: 'right'}}>
                5m
            </div>
            <div style={{color: 'white', fontSize: '14px', textAlign: 'right'}}>
                1h
            </div>
            <div style={{color: 'white', fontSize: '14px', textAlign: 'right'}}>
                1d
            </div>
        </div>
    )
}

export default TokensHeader