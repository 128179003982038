import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import React, {useContext, useEffect, useState} from "react";
import * as web3 from "@solana/web3.js";
import {fetchAllDigitalAssetWithTokenByOwner} from "@metaplex-foundation/mpl-token-metadata";
import {fromWeb3JsPublicKey} from "@metaplex-foundation/umi-web3js-adapters";
import oldStyles from "./oldStyles";
import {commafy, origin} from "./utils";
import Holding from "./Holding";
import {createUmi} from "@metaplex-foundation/umi-bundle-defaults";
import AppContext from "./AppContext";
import {css, StyleSheet} from "aphrodite";
import {PhantomWalletName} from "@solana/wallet-adapter-phantom";

const endpoint = "https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/"
const umi = createUmi(endpoint)

const styles = StyleSheet.create({
    primaryButton: {
        background: 'rgb(32 129 226)',
        borderRadius: '20px',
        padding: '10px 20px',
        fontWeight: '900',
        textAlign: 'center',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgb(69, 145, 222)',
        },
    },
})

const newSolToken = () => {
    return {
        uri: 'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Fsolana-labs%2Ftoken-list%40main%2Fassets%2Fmainnet%2FSo11111111111111111111111111111111111111112%2Flogo.png&fit=cover&width=256&height=256',
        name: 'Solana',
        amount: 0,
        symbol: 'SOL',
        value: 0,
        change: '0%',
        decimals: 9,
        isSol: true
    }
}

const getTokenInfos = async (token_addresses) => {
    const queryParams = token_addresses ? `?token_addresses=${token_addresses.join(',')}` : ''
    try {
        const headers = { 'Content-Type': 'application/json' };
        const response = await fetch(`${origin}/tokens/token_info${queryParams}`, {
            method: "GET",
            headers: headers,
        });
        const res = await response.json()
        return res.tokens;
    } catch (err) {
        return Promise.resolve([])
    }
};

const Holdings = ({setCoinSelected}) => {
    const { connection } = useConnection();
    const [tokens, setTokens] = useState([newSolToken()])
    const [isMini, setIsMini] = useState(false)

    const {socket, isMobile, socketOpen, trades, setHoldings} = useContext(AppContext)
    const [subscribed, setSubscribed] = useState(false)
    const [tokensSet, setTokensSet] = useState(false)

    const { publicKey, connect, disconnect, select } = useWallet();
    const handleWindowSizeChange = () => {
        setIsMini(window.innerWidth < 600)
    };

    // call your useEffect
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange()
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    if (trades) {
        tokens.sort((a, b) => {

            const priceA = trades[a?.tokenAddress]?.[0]?.price_of_token_in_sol || 0
            const priceB = trades[b?.tokenAddress]?.[0]?.price_of_token_in_sol || 0

            const amountA = Number(Number(a.amount) / Number(10 ** (a.decimals)))
            const amountB = Number(Number(b.amount) / Number(10 ** (b.decimals)))

            return (priceB * amountB) - (priceA * amountA)
        })
    }

    useEffect(() => {

        (async function () {
            if (publicKey) {
                let response = await connection.getParsedTokenAccountsByOwner(
                    publicKey, // owner here
                    {
                        programId: new web3.PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
                    }
                );

                const digitalAssetsWithToken = await fetchAllDigitalAssetWithTokenByOwner(umi,
                    fromWeb3JsPublicKey(publicKey)
                )

                const newBalance = await connection.getBalance(publicKey)

                const newHolding = {
                    uri: 'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Fsolana-labs%2Ftoken-list%40main%2Fassets%2Fmainnet%2FSo11111111111111111111111111111111111111112%2Flogo.png&fit=cover&width=256&height=256',
                    image: 'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Fsolana-labs%2Ftoken-list%40main%2Fassets%2Fmainnet%2FSo11111111111111111111111111111111111111112%2Flogo.png&fit=cover&width=256&height=256',
                    name: 'Solana',
                    amount: newBalance,
                    symbol: 'SOL',
                    token_address: 'So11111111111111111111111111111111111111112',
                    tokenAddress: 'So11111111111111111111111111111111111111112',
                    change: '0%',
                    decimals: 9,
                    isSol: true
                }

                console.log("[digitalAssetsWithToken]", digitalAssetsWithToken)

                const tokenAddresses = digitalAssetsWithToken.map(dawt => dawt.mint.publicKey)

                const tokenInfos = await getTokenInfos(tokenAddresses)

                const myMap = {}
                tokenInfos.forEach(ti => myMap[ti.token_address] = ti)

                const res = digitalAssetsWithToken.map(digitalAssetWithToken => ({
                    ...digitalAssetWithToken.mint, ...digitalAssetWithToken.token, ...digitalAssetWithToken.metadata
                }))

                console.log("[res]", res)


                res.forEach((token) => {
                    token.tokenAddress = token.mint
                    token.token_address = token.mint
                    token.historical_prices = myMap[token.mint]?.historical_prices
                    token.image = myMap[token.mint]?.image_uri
                })

                const newTokens = [newHolding, ...res]

                console.log("[newTokens]", newTokens)

                setTokens(newTokens)
                setHoldings(newTokens)
                setSubscribed(false)
                setTokensSet(true)
            } else {
                setTokens([newSolToken()])
                setHoldings([newSolToken()])
            }
        })()

    }, [publicKey])

    useEffect(() => {
        if (!subscribed && socketOpen && tokensSet && tokens) {
            const tokensToAdd = {
                add_symbols: tokens.map(token => token.mint)
            };
            socket.send(JSON.stringify(tokensToAdd));
            setSubscribed(true)
        }
        if (subscribed && !socketOpen) {
            setSubscribed(false)
        }
    }, [subscribed, socketOpen, tokensSet, tokens, socket])

    let totalValue = 0
    const priceOfSol = Number(trades['So11111111111111111111111111111111111111112']?.[0]?.price_of_token_in_usd) || 0
    tokens.forEach((token) => {
        const priceInSol = trades[token.tokenAddress]?.[0]?.price_of_token_in_sol || 0
        const amount = Number(Number(token.amount) / Number(10 ** (token.decimals)))

        const value = (priceInSol * amount * priceOfSol)

        totalValue += value
    })

    const handleConnect = async () => {
        try {
            select(PhantomWalletName)
            await connect();
        } catch (e) {
            console.error(e);
        }
    };

    const clickHandler = (token) => {
        if (token.token_address === "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v") {
            return
        }
        setCoinSelected(token)
    }

    return (
        <div style={{...oldStyles.box, flex: 1, flexGrow: 1, height: 0, boxSizing: 'border-box', maxHeight: isMobile ? '60vh' : '35vh', minHeight: '400px'}}>
            <div style={{fontWeight: '700', fontSize: '20px'}}>
                <div style={{fontSize: '14px', fontWeight: '400', color: 'rgba(255, 255, 255, 0.5)', marginBottom: '5px'}}>
                    {`Wallet Value`}
                </div>
                {`$${commafy(Math.floor(totalValue))}`}
            </div>
            {!publicKey && (
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: '20px', flexGrow: 1}}>
                    <div style={{color: 'rgba(255, 255, 255, 0.6)', flexGrow: 1, width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgba(255, 255, 255, 0.08)', borderRadius: '10px'}}>
                        Connect wallet to view holdings
                    </div>
                    <div onClick={handleConnect} className={css(styles.primaryButton)}> Connect Wallet </div>
                </div>
            )}
            <div  style={{display: 'flex', flexWrap: 'wrap', gap: '5px', width: '100%', overflowY: 'auto', scrollbarWidth: 'none'}}>
                {tokens.map(holding => <Holding key={holding.name} isMini={isMini} handleClick={clickHandler} holding={holding} />)}
            </div>
        </div>
    )
}

export default Holdings